<template>
  <div>
    <link
      rel="resource"
      type="application/l10n"
      href="/es-viewer.properties.txt"
    >
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card
          :title="$t('Documents.Previous Analysis.title')"
        >
          <b-row
            class="d-flex flex-row"
          >
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :label="$t('Documents.Previous Analysis.perPage.label')"
                label-for="perPageSelect"
                :description="$t('Documents.Previous Analysis.perPage.description')"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <!-- Datepicker Input Begin -->
            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.fromDate.description')"
                :label="$t('Documents.Previous Analysis.fromDate.label')"
                label-for="from-date-text"
                :invalid-feedback="$t('Documents.Previous Analysis.fromDate.feedback')"
                :state="fromDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="from-date-text"
                    v-model="fromDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="fromDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="fromDateString"
                      :initial-date="fromInitialDate"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="fromDateOutlineVariant()"
                      size="sm"
                      aria-controls="from-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.toDate.description')"
                :label="$t('Documents.Previous Analysis.toDate.label')"
                label-for="to-date-string"
                :invalid-feedback="$t('Documents.Previous Analysis.toDate.feedback')"
                :state="toDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="to-date-text"
                    v-model="toDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="toDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="toDateString"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="toDateOutlineVariant()"
                      size="sm"
                      aria-controls="to-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Datepicker Input End -->
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.sort.description')"
                :label="$t('Documents.Previous Analysis.sort.label')"
                label-for="sortBySelect"
              >
                <!-- TODO: Apply translation to this component-->
                <b-input-group>
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="align-items-center justify-content-end"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.filter.description')"
                :label="$t('Documents.Previous Analysis.filter.label')"
                label-for="filterInput"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('Documents.Previous Analysis.filter.placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      variant="outline-primary"
                      @click="filter = ''"
                    >
                      {{ $t('Documents.Previous Analysis.filter.buttonText') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >

                <template
                  #cell(status)="data"
                >
                  <b-badge
                    v-if="data.value!==0"
                    :variant="statuses[1][data.value]"
                  >
                    {{ statuses[0][data.value] }}
                  </b-badge>
                  <b-spinner
                    v-if="data.value===0"
                    class="mr-1"
                    variant="primary"
                  />
                </template>

                <template #cell(analysis_type)="data">
                  {{ analysisTypes[0][data.value] }}
                </template>

                <template #cell(request_date)="data">
                  <!-- human format: (new Date(Date.parse(data.value))).toLocaleDateString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' })  -->
                  {{ (new Date(Date.parse(data.value))).toLocaleString("es-CL") }}
                </template>

                <template #cell(action)="row">
                  <b-button
                    v-if="row.value && row.item.actionType === 'ep_with_mod'"
                    v-b-modal.modal-mods
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showMods(row.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="row.value && (
                        row.item.actionType === 'eeud' ||
                        row.item.actionType === 'rhh' ||
                        row.item.actionType === 'ep_with_mod' ||
                        row.item.actionType === 'ep_without_mod' ||
                        row.item.actionType === 'ep')"
                    v-b-modal.modal-pdf
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showPdf(row.item)"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                  <b-button
                    v-b-modal.modal-doc-upload
                    v-if="row.value && (row.item.actionType === 'rhh')"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showAlert(row.item)"
                  >
                    <feather-icon icon="AlertTriangleIcon" />
                  </b-button>
                  <!--b-icon-eye v-if="data.value"/-->
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-modal
        id="modal-doc-upload"
        size="xl"
        centered
        title="Este análisis requiere de información adicional. Por favor, cargue la siguiente documentación:"
      >
        <b-table
          small
          responsive="sm"
          :fields="documentation.fields"
          :items="documentation.items"
        >
          <template #cell(action)="row">
            <b-row>
              <b-col>
                <b-form-file
                  v-model="row.item.file"
                  :state="row.item.valid"
                  :placeholder="$t('Documents.Previous Analysis.fileInput.placeholders.default')"
                  :drop-placeholder="$t('Documents.Previous Analysis.fileInput.placeholders.drop')"
                  :browse-text="$t('Documents.Previous Analysis.fileInput.buttonTexts.browse')"
                  accept="application/pdf"
                />
              </b-col>
              <b-col
                cols="2"
              >
                <b-button
                  v-b-tooltip.hover.top="$t('Documents.Previous Analysis.fileInput.buttonTexts.save')"
                  :variant="saveManualDocButtonVariant(row.item)"
                  class="btn-icon rounded-circle"
                  @click="saveManualDoc(row.item)"
                >
                  <feather-icon icon="FilePlusIcon" />
                </b-button>
              </b-col>
            </b-row>
          </template>
          <template #cell(comments)="row">
            <b-row>
              <b-col align="center">
                <b-button
                  v-b-modal.modal-doc-comments
                  v-b-tooltip.hover.top="$t('Documents.Previous Analysis.jobComments.buttonText')"
                  variant="flat-primary"
                  :disabled="row.item.id === -1"
                  class="btn-icon rounded-circle"
                  @click="viewDocComments(row.item)"
                >
                  <feather-icon icon="MessageSquareIcon" />
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
        <template #modal-footer>
          <b> Tamaño máximo de archivo: 20 [MB] </b> <!-- TODO: Move to i18n -->
        </template>
      </b-modal>
      <b-modal
        id="modal-doc-comments"
        :title="$t('Documents.Previous Analysis.jobComments.modalTitle')"
        hide-footer
        centered
      >
        <b-overlay
          id="overlay-doc-comments"
          :show="showDocCommentsOverlay"
          variant="transparent"
          opacity="1.00"
          blur="0.5em"
          rounded="sm"
        >
          <b-row
            v-for="(comment, index) in documentation.currentComments.data"
            :key="`row-comment-${index}`"
          >
            <b-col>
              <b-card
                :title="comment.type"
                border-variant="secondary"
              >
                {{ comment.content }}
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
      <b-modal
        id="modal-pdf"
        size="xl"
        hide-footer
        centered
        :title="currentPdfTitle"
      >
        <b-overlay
          id="overlay-pdf"
          :show="pdfOverlay.show"
          :variant="pdfOverlay.variant"
          :opacity="pdfOverlay.opacity"
          :blur="pdfOverlay.blur"
          rounded="sm"
        >
          <vue-pdf-app
            style="height: 90vh; width: 100%;"
            :pdf="currentPdfData"
            :file-name="currentPdfName"
          />
        </b-overlay>
      </b-modal>
      <b-modal
        id="modal-mods"
        size="lg"
        hide-footer
        centered
        :title="currentCompanyName"
      >
        <b-table
          small
          :fields="modifications.fields"
          :items="modifications.items"
        >
          <template #cell(fecha_pub)="data">
            <!-- human format: (new Date(Date.parse(data.value))).toLocaleDateString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' })  -->
            {{ (new Date(Date.parse(data.value))).toLocaleString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' }) }}
          </template>

          <template #cell(action)="row">
            <!--v-b-modal.modal-mods-->
            <b-button
              v-b-modal.modal-pdf
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="showModPdf(row)"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </template>
        </b-table>
      </b-modal>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BSpinner, BOverlay,
  BFormDatepicker, BFormFile, VBTooltip, VBModal,
} from 'bootstrap-vue'

import {
  sub, endOfToday, format, endOfDay, isBefore, startOfToday, parse, isValid, formatRFC3339, // min,
} from 'date-fns'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormFile,
    VuePdfApp,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    // Date initialization
    const todaysEnd = endOfToday()
    const todaysStart = startOfToday()
    const oneMonthBefore = sub(todaysStart, { months: 1 })

    return {
      pdfOverlay: {
        variant: 'transparent',
        opacity: 1.00,
        blur: '0.5em',
        show: true,
      },
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPdfData: '',
      currentPdfTitle: '',
      currentPdfName: '',
      currentJobId: 0,
      currentCompanyName: '',
      documentation: {
        fields: [
          { key: 'doctype', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.document') },
          { key: 'status', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.status') },
          { key: 'action', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.action') },
          { key: 'comments', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.viewComments') },
        ],
        // todo: items should be obtained directly from backend
        items: [
          {
            id: -1,
            doctype: 'Escritura de constitución de la sociedad',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
          {
            id: -1,
            doctype: 'Inscripción del extracto de constitución en el Conservador de Bienes Raíces respectivo, con vigencia menor a un año y anotaciones marginales',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
          {
            id: -1,
            doctype: 'Publicación del extracto de constitución en el Diario Oficial',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
          {
            id: -1,
            doctype: 'Escrituras de modificación de sociedad en el caso de existir, si hay más de una, usar sección "otros"',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
          {
            id: -1,
            doctype: 'Escritura de personerías (en caso de ser S.A., SpA o existir delegación general o parcial de facultades legales)',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
          {
            id: -1,
            doctype: 'Otros',
            action: 'Upload',
            status: this.$t('Documents.Previous Analysis.fileInput.placeholders.notUploaded'),
            file: null,
            valid: null,
          },
        ],
        currentComments: {},
      },
      showDocCommentsOverlay: false,
      modifications: {
        fields: [
          { key: 'edicion', label: 'Edición' },
          { key: 'company_name', label: 'Nombre' },
          { key: 'fecha_pub', label: 'Publicación' },
          { key: 'action', label: 'Acción' },
        ],
        items: [],
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.id') },
        { key: 'status', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.status'), sortable: true },
        /* {
          key: 'avatar', label: 'Avatar',
        }, */
        // { key: 'full_name', label: 'Full Name', sortable: true },
        // { key: 'post', label: 'Post', sortable: true },
        // 'email',
        // 'city',
        // 'start_date',
        { key: 'rut', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.rut') },
        { key: 'name', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.name') },
        { key: 'analysis_type', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.analysis_type') },
        { key: 'request_date', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.request_date') },
        { key: 'action', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.action') },
      ],
      items: [
        /*
        {
          id: 5,
          name: '',
          request_date: '17/June/2021 171:06:06.168',
          rut: '77.171.531-1',
          status: 1,
          type: 3,
        },
        {
          id: 4,
          name: '',
          request_date: '17/June/2021 171:06:06.168',
          rut: '77.171.531-1',
          status: 1,
          type: 3,
        }, */
      ],
      // TODO: Apply translation to statuses' labels
      statuses: [{
        0: 'En Proceso',
        1: 'Listo',
        2: 'Listo Con Mod',
        3: 'Listo Sin Mod',
        4: 'Sin Conformidad',
        5: 'Requiere Ayuda',
        6: 'Con actividades',
        7: 'Sin actividades',
        8: 'Listo Sin Mod',
      },
      {
        1: 'light-success',
        2: 'light-info',
        3: 'light-info',
        4: 'warning',
        5: 'light-primary',
        6: 'light-success',
        7: 'dark',
        8: 'light-info',
      }],
      analysisTypes: [{
        0: 'Persona Natural',
        1: 'Formato Ripley',
        2: 'Bancarias Especiales',
        3: 'Bancarias Normales',
        4: 'Operaciones de Credito',
        5: 'Formato MAF',
      }],
      toDateString: format(todaysEnd, 'yyyy-MM-dd'),
      fromDateString: format(oneMonthBefore, 'yyyy-MM-dd'),
      fromInitialDate: oneMonthBefore,
      maxDate: todaysEnd,
    }
  },
  sockets: {
    onJobStatusUpdate(data) {
      // console.log(data)
      if (data.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.couldntReport'),
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.successReport'),
            variant: 'success',
          },
        })
        this.fetchData()
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    toDateString: 'fetchWhenValid',
    fromDateString: 'fetchWhenValid',
  },
  created() {
    // eslint-disable-next-line
    this.$nextTick(function () {
      this.fetchData()
    })
    // console.log('Call backend if you want')
    // console.log(formatRFC3339(this.fromDateAsDate()))
    // console.log(formatRFC3339(this.toDateAsDate()))
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
    // this.$http.get('/v2/consulta-rut/count')
    //   .then(response => {
    //     this.totalRows = response.data.count
    //   })
    //   .catch(error => {
    //     console.log('There was an error: ', error)
    //   })
  },
  methods: {
    fetchData() {
      this.$http.get('/v2/consulta-rut/count')
        .then(response => {
          this.totalRows = response.data.count
          this.$http.get('/v2/consulta-rut/list', {
            params: {
              limit: this.totalRows, // this.perPage,
              // offset: this.perPage * (this.currentPage - 1),
              ...(
                // Include date params only when both are valid
                (this.fromDateState() === null
                  && this.toDateState() === null) ? {
                    fromDate: formatRFC3339(this.fromDateAsDate()),
                    toDate: formatRFC3339(this.toDateAsDate()),
                  } : {}
              ),
            },
          })
            .then(responseEntries => {
              this.items = responseEntries.data.entries
              this.totalRows = this.items.length
              // TODO: this.subscribeToPendingJobs() // this is not necessary to do, since subscription is done on job request
              // console.log(this.items)
            })
            .catch(error => {
              console.error('There was an error: ', error)
            })
        })
        .catch(error => {
          console.error('There was an error: ', error)
        })
    },
    subscribeToPendingJobs() {
      // eslint-disable-next-line
      for (const entry of this.items) {
        if (entry.status === 0) {
          // if job is pending...
          this.$socket.emit('addJobToWaitingList', entry.id)
        }
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    matchStuff(docs) {
      // Does something!
      docs.forEach(doc => {
        const index = this.documentation.items.findIndex(item => item.doctype === doc.title)
        if (index >= 0 && this.documentation.items[index].id < doc.doc_id) {
          this.documentation.items[index].id = doc.doc_id
          this.documentation.items[index].status = doc.status[0].toUpperCase() + doc.status.slice(1)
        }
      }, this)
    },
    viewDocComments(doc) {
      this.showDocCommentsOverlay = true
      this.documentation.currentComments = {}
      this.$http.get(`/v2/manual/jobs/${this.currentJobId}/doc/${doc.id}/comments`)
        .then(response => {
          this.documentation.currentComments = response.data
          // console.log(response.data)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.showDocCommentsOverlay = false
        })
    },
    showModPdf(entry) {
      this.currentPdfData = ''
      this.pdfOverlay.show = true

      this.$http.get('/v2/ep-mod-pdf', {
        params: {
          id: this.currentJobId,
          index: entry.index,
        },
      }).then(response => {
        this.currentPdfData = `data:text/plain;base64,${response.data.data}`
        this.pdfOverlay.show = false
      }).catch(error => {
        console.error('There was an error:', error)
        this.pdfOverlay.show = false
        this.$swal({
          title: this.$i18n.t('SwAl.titles.error'),
          html: this.$i18n.t('SwAl.htmls.couldntLoadPdf', { error }),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    showMods(item) { // (item) {onView
      this.currentCompanyName = item.name
      this.currentJobId = item.id
      this.$http.get('/v2/ep-mod', {
        params: {
          id: item.id,
        },
      }).then(response => {
        this.modifications.items = response.data.mods
        // console.log(response.data.mods)
      })
      // console.log('Its time to check item:', item)
    },
    showAlert(item) {
      this.currentJobId = item.id
      // console.log(item)
      this.$http.get(`/v2/manual/jobs/${this.currentJobId}/docs`)
        .then(response => {
          // Assign stuff
          this.matchStuff(response.data.documents)
          // console.log(response.data)
        })
        .catch(error => {
          // Do something (?)
          console.error(error)
        })
    },
    showPdf(item) {
      this.currentPdfTitle = item.name
      this.currentPdfName = `${item.id} - ${item.name.replace(/[^\w\s]/gi, '')}.pdf`
      this.currentPdfData = ''
      this.pdfOverlay.show = true
      this.$http.get('/v2/jobs/pdf', {
        params: {
          job_id: item.id,
          format: 'base64',
        },
      }).then(response => {
        this.currentPdfData = `data:text/plain;base64,${response.data.data}`
        // console.log('Data:', item.name)
        this.pdfOverlay.show = false
        // TODO: this.subscribeToPendingJobs() // this is not necessary to do, since subscription is done on job request
      }).catch(error => {
        console.error('There was an error: ', error)
        this.pdfOverlay.show = false
      })
    },
    saveManualDocButtonVariant(item) {
      let variant = 'flat-primary'
      if (item.valid === true) {
        variant = 'flat-success'
      } else if (item.valid === false) {
        variant = 'flat-danger'
      }
      return variant
    },
    saveManualDoc(item) {
      if (item.file) {
        this.$http.post(`/v2/manual/jobs/${this.currentJobId}/doc`, item.file, {
          headers: {
            'Content-Type': 'application/pdf',
            'Attributes-Type': 'application/pdf',
          },
          params: {
            title: item.doctype,
          },
        })
          .then(response => {
            // console.log(response.data)
            const itemRef = item
            itemRef.valid = true
            itemRef.id = response.data.doc_id
            itemRef.status = response.data.status[0].toUpperCase() + response.data.status.slice(1)
            this.makeToast(
              this.$i18n.t('Toast.titles.saved'), // Title
              this.$i18n.t('Toast.texts.successSave'), // Text
              'success', // Variant
            )
          })
          .catch(error => {
            console.error('There was an error: ', error)
            const itemRef = item
            itemRef.valid = false
            this.makeToast(
              this.$i18n.t('Toast.titles.errNoID'), // Title
              this.$i18n.t('Toast.texts.couldntSave'), // Text
              'danger', // Variant
            )
          })
      }
    },
    // Beginning of date-related functions
    fromDateAsDate() {
      return parse(this.fromDateString, 'yyyy-MM-dd', new Date())
    },
    toDateAsDate() {
      return endOfDay(parse(this.toDateString, 'yyyy-MM-dd', new Date()))
    },
    // Validator for From-Date datepicker
    fromDateState() {
      return isValid(this.fromDateAsDate()) ? null : false
    },
    // Validator for To-Date datepicker
    toDateState() {
      // console.log('toDateState:', this.fromDateAsDate(), this.toDateAsDate())
      // console.log(isBefore(this.fromDateAsDate(), this.toDateAsDate()))
      return isBefore(this.fromDateAsDate(), this.toDateAsDate()) ? null : false
    },
    fromDateOutlineVariant() {
      if (this.fromDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    toDateOutlineVariant() {
      if (this.toDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    fetchWhenValid() {
      // If both validators pass, fetch from backend
      if (this.fromDateState() === null && this.toDateState() === null) {
        this.fetchData()
      }
    },
    // End of date-related functions
    makeToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
